import Analytics from './analytics'
import getCurrentIp from './../services/getCurrentIp'

const registerUserProperties = ({ properties, analytics }) => {
  analytics.setUserProperties(properties)
  analytics.logEvent('WEB_SIGNUP')
}

const registerUserPropertiesToAnalytics = () => {
  const userProperties = {
    environment: process.env.REACT_APP_ENVIRONMENT || 'local'
  }

  return getCurrentIp()
    .then(ip => {
      userProperties['ipAddress'] = ip
      console.log(userProperties)
      registerUserProperties({ properties: userProperties, analytics: Analytics })
    })
    .catch(() => registerUserProperties({ properties: userProperties, analytics: Analytics }))
}

export default registerUserPropertiesToAnalytics
